import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Japan from "./pages/Japan";
import Main from "./pages/Main";
import Contact from "./pages/Contact";
import usePageTracking from "./lib/usePackageTracking";
import ConsentBanner from "./lib/ConsentBanner";
import { updateConsent } from "./lib/gtag";
import './App.css';

function App() {
  const [consentGiven, setConsentGiven] = useState(() => {
    return localStorage.getItem("consentGiven") === "true";
  });

  usePageTracking(consentGiven);

  const handleConsent = () => {
    setConsentGiven(true);
    localStorage.setItem("consentGiven", "true");
    updateConsent();
  };

  return (
    <>
      {!consentGiven && <ConsentBanner onAgree={handleConsent} />}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/jp" element={<Japan />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
