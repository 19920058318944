// usePageTracking.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { gtag, loadGtagScript, setDefaultConsent, GA_MEASUREMENT_ID } from "./gtag";

function usePageTracking(consentGiven) {
  const location = useLocation();

  useEffect(() => {
    // Set up Google Analytics based on consent status
    if (consentGiven) {
      loadGtagScript();
    } else {
      setDefaultConsent();
    }
  }, [consentGiven]);

  useEffect(() => {
    if (consentGiven && typeof window.gtag === "function") {
      gtag("config", GA_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    }
  }, [location, consentGiven]);
}

export default usePageTracking;
