import React, { useRef } from 'react';
import '../App.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        alert('Email successfully sent.');
      }, (error) => {
        console.log(error.text);
        alert('Email Failed. Please try again later.');
      });
  };

  return (
    <>
      <header className="header">
        <div className="header-left">
          <div className="header-logo">
            <a href="https://www.hayashi-seimen.com/" target="_blank"><img src="../images/logo.png" alt="Hayashi Seimen Europe"></img></a>
          </div>
          <p>Hayashi Seimen Europe</p>
        </div>
        <div className="header-right">
          <div className="header-language text-button">
            <a href="/">HOME</a>
          </div>
        </div>
      </header>
      <main>
        <section className="contact-container">
          <h3 className="contact-title">Contact (お問い合わせ)</h3>
          <p>※required / ※は必須項目です</p>
          <form ref={form} className="contact-form" onSubmit={sendEmail}>
            <div className="form-group">
              <label>Company Name (会社名)</label>
              <input type="text" name="company" />
            </div>
            <div className="form-group">
              <label>Name (お名前) <span className="required">*</span></label>
              <input type="text" name="from_name" required />
            </div>
            <div className="form-group">
              <label>Email (メールアドレス) <span className="required">*</span></label>
              <input type="email" name="reply_to" required />
            </div>
            <div className="form-group">
              <label>Message (お問い合わせ内容) <span className="required">*</span></label>
              <textarea name="message" required></textarea>
            </div>
            <input type="hidden" name="website" value="Hayashi Seimen Europe" />
            <button type="submit" className="submit-button">Send (送信)</button>
          </form>
        </section>
      </main>
      <footer>
        <div className="footer-box">
          <div className="footer-box__logo">
            <a href="https://www.hayashi-seimen.com/" target="_blak"><img src="../images/logo.png" alt="Hayashi Seimen Europe"></img></a>
          </div>
          <div className="footer-box__adress">
            <address>
              <p>Hayashi Seimen Europe</p>
              <dl><dt>Adress :</dt><dd>Prinzenalle 7, 40549 Düsseldorf Germany</dd></dl>
              <dl><dt>Mail :</dt><dd><div class="text-button"><a href="mailto:info.eu@hayashi-seimen.com">info.eu@hayashi-seimen.com</a></div></dd></dl>
            </address>
          </div>
        </div>
        <div className="footer-copy">
          <small>© 2024<script type="text/javascript">myDate = new Date() ;myYear = myDate.getFullYear ();document.write(myYear);</script> Hayashi Seimen Europe</small>
        </div>
      </footer>
    </>
  );
};

export default Contact